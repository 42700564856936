import Navbar from "../../components/Navbar/Navbar";
import ResetPasswordPanel from "../../components/ResetPasswordPanel/ResetPasswordPanel";
function ResetPasswordDisplay(){
    return (
        <>
            <Navbar/>
            <ResetPasswordPanel/>
        </>
    )
}

export default ResetPasswordDisplay;