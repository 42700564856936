import styled from "styled-components";

/*
    Function and components responsible for entire ui
 */

export const MainContainer = styled.div`
    //margin: 0 auto;
    max-width: 100%;
    height: 100%;
    
`;



